main {
  /*margin-top: 120px;*/
}

@media screen and (max-width: 960px) {
  main {
    /*margin-top: 60px;*/
  }
}




a {
  text-decoration: none;
}

.link-no-style {
  text-decoration: none;
}
